<template>
    <a
        class="inline-block py-5 px-7 cursor-pointer lg:hidden"
        :title="$t('toggle_navigation')"
        @click="toggle()"
    >
        <icon-burger
            class="w-5 h-auto"
            filled
            :class="{ 'fill-white': white }"
        />
    </a>
</template>

<script setup lang="ts">
import IconBurger from "~/public/icons/burger.svg";

const sidebarStore = useSidebarStore();
const cookieStore = useCookieStore();

defineProps({
    white: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(["toggle"]);

const toggle = () => {
    cookieStore.trackEvent("sidebar_toggle");
    sidebarStore.toggleSidebar();

    emit("toggle");
};
</script>
